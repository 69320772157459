import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from 'react-router-dom';
import i18n from './i18n';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Skills from './components/Skills/Skills';
import WorkExperience from './components/WorkExperience/WorkExperience';
import Footer from './components/Footer/Footer';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContent />}>
          <Route path="/:lang" element={<MainContent />} />
        </Route>
      </Routes>
    </Router>
  );
};

const availableLanguages = [
  { code: 'en', name: 'English', flag: '/assets/images/flags/uk.png' },
  { code: 'pl', name: 'Polish', flag: '/assets/images/flags/pl.png' },
  { code: 'de', name: 'German', flag: '/assets/images/flags/de.png' },
];

const MainContent = () => {
  const { lang } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (lang && availableLanguages.some(l => l.code === lang)) {
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage('en');
    }

    // Symulacja ładowania
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [lang, location]);

  if (loading) {
    return (
      <div className="loading-spinner">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
      <Navbar availableLanguages={availableLanguages} />
      <div className="container">
        <Hero />
        <Skills />
        <WorkExperience />
        <Projects />
        <Contact />
      </div>
      <Footer />
    </>
  );
};

export default App;
