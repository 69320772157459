import React from 'react';
import './Contact.css'
import ContactInfoCard from './ContactInfoCard/ContactInfoCard';
import ContactForm from './ContactForm/ContactForm';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <section className="contact-container" id="contact">
        <h5>{t('menu.contact')}<span>.</span></h5>
        <div className="contact-content">
          <div style={{ flex: 1 }}>
            <ContactInfoCard
              iconUrl="/assets/linkedin.svg"
              text="https://www.linkedin.com/in/lukasz-szulzyk/" />
            <ContactInfoCard
              iconUrl="/assets/github.svg"
              text="https://github.com/Lukasz-Szulzyk" />
          </div>

          <div style={{ flex: 1 }}>
            <ContactForm />
          </div>
        </div>
    </section>
  )
};

export default Contact;