import React from "react";
import "./MobileNav.css";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const MobileNav = ({ isOpen, toggleMenu, availableLanguages }) => {
  const { t } = useTranslation();

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const offset = 120;
    const elementPosition =
      element.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    toggleMenu();
  };

  return (
    <>
      <div
        className={`mobile-menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <div className="mobile-menu-container">
          <h1 className="logo">
            <span>Smart</span>Design<span className="dot">.</span>
          </h1>

          <ul>
            <li>
              <motion.a
                className="menu-item"
                onClick={() => scrollToSection("hero")}
              >
                {t("menu.home")}
              </motion.a>
            </li>
            <li>
              <motion.a
                className="menu-item"
                onClick={() => scrollToSection("skills")}
              >
                {t("menu.skills")}
              </motion.a>
            </li>
            <li>
              <motion.a
                className="menu-item"
                onClick={() => scrollToSection("experience")}
              >
                {t("menu.experience")}
              </motion.a>
            </li>
            <li>
              <motion.a
                className="menu-item"
                onClick={() => scrollToSection("projects")}
              >
                {t("menu.projects")}
              </motion.a>
            </li>
            <li>
              <button
                className="contact-btn"
                onClick={() => scrollToSection("contact")}
              >
                {t("menu.contact")}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileNav;
