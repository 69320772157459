import React, { useState } from 'react';
import './Skills.css';
import SkillCard from './SkillCard/SkillCard.jsx';
import SkillsInfoCard from './SkillsInfoCard/SkillsInfoCard.jsx';
import { useTranslation } from 'react-i18next';

const Skills = () => {
  const { t } = useTranslation();
  
  const skillsData = t('skills', { returnObjects: true });
  const sections = Object.keys(skillsData);
  const [selectedSkill, setSelectedSkill] = useState(skillsData[sections[0]]);

  const handleSelectSkill = (section) => {
    setSelectedSkill(skillsData[section]);
  };

  return (
    <section className="skills-container" id="skills">
      <h5>{t('menu.skills')}<span>.</span></h5>
      <div className="skills-content">
        <div className="skills">
          {sections.map((section) => (
            <SkillCard
              key={section}
              icon={skillsData[section].icon}
              title={skillsData[section].title}
              isActive={selectedSkill.title === skillsData[section].title}
              onClick={() => handleSelectSkill(section)}
            />
          ))}
        </div>
        <div className="skills-info">
          <SkillsInfoCard
            heading={selectedSkill.title}
            skills={selectedSkill.skills}
          />
        </div>
      </div>
    </section>
  );
};

export default Skills;
