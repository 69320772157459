import React from "react";
import "./ProjectItem.css";
import { useTranslation } from 'react-i18next';

const ProjectItem = ({ title, description, tech, imageUrl, demoLink, codeLink, isActive, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={`project-item ${isActive ? "active" : ""}`} onClick={onClick}>
      <article>
        <img src={imageUrl} alt={title} className="project-image" />
        <div className="project-text">
          <h4>{title}</h4>
          <span className="project-description">{description}</span>
          
          <div className="project-links">
            <a href={demoLink} target="_blank" rel="noreferrer" className="project-link">
              {t('projects_action.url')}
            </a>
            {codeLink && (
              <a href={codeLink} target="_blank" rel="noreferrer" className="project-link">
                {t('projects_action.code')}
              </a>
            )}
          </div>
          
          {tech && tech.length > 0 && (
            <div className="project-tech">
              {tech.map((techItem, index) => (
                <span key={index}>{techItem}</span>
              ))}
            </div>
          )}

        </div>
      </article>
    </div>
  );
};

export default ProjectItem;
