import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {

    const { t } = useTranslation();
    const form = useRef();

    const [showPopup, setShowPopup] = useState(false);

    const handleShowPopup = () => {
        setShowPopup(true);
        setTimeout(() => {
        setShowPopup(false);
        }, 3000);
    };

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm(
            'service_qmyrsxa', 
            'template_lj7nhqv', 
            form.current, {
          publicKey: '-MKKk_e8eodRyd3ZK',
        })
        .then(
          () => {
            handleShowPopup();
            form.current.reset();
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };

  return (
    <div className="contact-form-content">
        <form ref={form} onSubmit={sendEmail}>
            <div>
                <input type="text" name="user_name" placeholder={t('contact.name')} required />
            </div>
            <div>
                <input type="text" name="user_email" placeholder={t('contact.email')} required />
            </div>
            <div>
                <textarea type="text" name="message" placeholder={t('contact.message')} required />
            </div>
            <input type="submit" value={t('contact.send')} />
        </form>
        {showPopup && (
          <div className="popup" >
            {t('contact.message_sent')}
          </div>
        ) }
    </div>
  )
}

export default ContactForm