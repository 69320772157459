import React, { useState } from "react";
import "./Navbar.css";
import MobileNav from "./MobileNav/MobileNav";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = ({ availableLanguages }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const offset = 120;
    const elementPosition =
      element.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const changeLanguage = (lang) => {
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
      let newPath = location.pathname;
      let pathSegments = newPath.split('/').filter(Boolean);
      const currentLang = pathSegments[0];

      if (availableLanguages.some(l => l.code === currentLang)) {
        pathSegments[0] = lang;
      } else {
        pathSegments = [lang, ...pathSegments.slice(1)];
      }
  
      newPath = `/${lang}/${pathSegments.slice(1).join('/')}`;
  
      navigate(newPath, { replace: true });
    }
  };
  
  const currentLang = i18n.language;

  return (
    <>
      <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} availableLanguages={availableLanguages} />

      <nav className="nav-wrapper">
        <div className="nav-content">
          <h1 className="logo">
            <span>Smart</span>Design<span className="dot">.</span>
          </h1>
          <div className="language-switcher-mobile">
            {availableLanguages
                .filter(lang => lang.code !== currentLang)
                .map(lang => (
                <img
                    key={lang.code}
                    src={lang.flag}
                    alt={`${lang.name} Flag`}
                    className="language-flag"
                    onClick={() => changeLanguage(lang.code)}
                />
                ))}
            </div>
          <ul>
            <li>
              <motion.a
                className="menu-item"
                onClick={() => scrollToSection("hero")}
              >
                {t('menu.home')}
              </motion.a>
            </li>
            <li>
              <motion.a
                className="menu-item"
                onClick={() => scrollToSection("skills")}
              >
                {t('menu.skills')}
              </motion.a>
            </li>
            <li>
              <motion.a
                className="menu-item"
                onClick={() => scrollToSection("experience")}
              >
                {t('menu.experience')}
              </motion.a>
            </li>
            <li>
              <motion.a
                className="menu-item"
                onClick={() => scrollToSection("projects")}
              >
                {t('menu.projects')}
              </motion.a>
            </li>
            <li>
              <motion.button
                className="contact-btn"
                onClick={() => scrollToSection("contact")}
              >
                {t('menu.contact')}
              </motion.button>
            </li>
            <li className="language-switcher">
              {availableLanguages
                .filter(lang => lang.code !== currentLang)
                .map(lang => (
                  <img
                    key={lang.code}
                    src={lang.flag}
                    alt={`${lang.name} Flag`}
                    className="language-flag"
                    onClick={() => changeLanguage(lang.code)}
                  />
                ))}
            </li>
          </ul>

          <button className="menu-btn" onClick={toggleMenu}>
            <span className={"material-symbols-outlined"}>
              {openMenu ? "close" : "menu"}
            </span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
