import React, { useState } from 'react';
import './WorkExperience.css';
import { useTranslation } from 'react-i18next';
import schoolIcon from "../../assets/school.svg";
import workIcon from "../../assets/work.svg";

const WorkExperience = () => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imgUrl) => {
    setSelectedImage(imgUrl);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  return (
    <section className="experience-container" id="experience">
      <h5>{t('experience.title')}<span>.</span></h5>
      <div className="experience-content">
        <div className="timeline">
          {t('experience.timeline', { returnObjects: true }).map((element, index) => (
            <div key={index} className="timeline-item">
              <div className="timeline-icon">
                <img
                  src={element.icon === "school" ? schoolIcon : workIcon}
                  alt="icon"
                  className="icon"
                />
              </div>
              <div className="timeline-content">
                <h3 className="date">{element.date}</h3>
                <h2 className="title">{t(element.title)}</h2>
                <p className="location">{t(element.location)}</p>
                <p
                  className="description"
                  dangerouslySetInnerHTML={{ __html: t(element.description) }}
                ></p>

                {element.files && (
                  <div className="timeline-files">
                    {element.files.map((file, idx) => (
                      <span key={idx} className="timeline-file">
                        <span
                          onClick={() =>
                            file.type === 'img'
                              ? handleImageClick(file.url)
                              : window.open(file.url, '_blank', 'noopener,noreferrer')
                          }
                          className="file-link"
                        >
                          {file.name}
                        </span>
                      </span>
                    ))}
                  </div>
                )}

                {element.tech && (
                  <div className="timeline-tags">
                    {element.tech.map((tech, idx) => (
                      <span key={idx} className="timeline-tag">
                        {tech}
                      </span>
                    ))}
                  </div>
                )}

              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedImage && (
        <div className="image-viewer" onClick={handleCloseImage}>
          <div className="image-container">
            <img src={selectedImage} alt="Selected" />
          </div>
        </div>
      )}
    </section>
  );
};

export default WorkExperience;
