import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Projects.css';
import { motion } from 'framer-motion';
import ProjectItem from './ProjectItem/ProjectItem.jsx';

const Projects = () => {
    const { t, i18n } = useTranslation();
    
    const [activeIndex, setActiveIndex] = useState(null);
    const [categories, setCategories] = useState({});
    const [activeFilter, setActiveFilter] = useState('all');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

    useEffect(() => {
        const projects = t('projects', { returnObjects: true }) || [];
        const projectCategories = t('projects_categories', { returnObjects: true }) || {};

        setCategories(projectCategories);

        const filtered = activeFilter === 'all'
            ? projects
            : projects.filter((project) => project.tags.includes(activeFilter));

        setAnimateCard({ y: 100, opacity: 0 });
        const animationTimeout = setTimeout(() => {
            setFilteredProjects(filtered);
            setAnimateCard({ y: 0, opacity: 1 });
        }, 500);

        return () => clearTimeout(animationTimeout);

    }, [t, i18n.language, activeFilter]);

    const handleWorkFilter = (item) => {
        setActiveFilter(item);
    };

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
      };

    return (
        <section className="projects-container" id="projects">
            <h5>{t('menu.projects')}<span>.</span></h5>
            <div className="projects-content">
                <div className="projects-filter">
                    {Object.keys(categories).map((key) => (
                        <div key={key}
                            onClick={() => handleWorkFilter(key)}
                            className={`projects-filter-item ${activeFilter === key ? 'item-active' : ''}`}
                        >
                            {categories[key]}
                        </div>
                    ))}
                </div>
                
                <motion.div
                    animate={animateCard}
                    transition={{ duration: 0.5, delayChildren: 0.5 }}
                    className="projects-portfolio"
                >
                    {filteredProjects.map((work, index) => (
                        <ProjectItem 
                        key={work.title} 
                        isActive={activeIndex === index}
                        onClick={() => handleToggle(index)}
                        {...work} />
                    ))}
                </motion.div>
            </div>
        </section>
    );
};

export default Projects;
