import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import plTranslation from './locales/pl.json';
import deTranslation from './locales/de.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      pl: { translation: plTranslation },
      de: { translation: deTranslation }
    },
    lng: 'en', // domyślny język
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;